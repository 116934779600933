import React from "react";
import Image from "next/image";
import Light_Logo from "../public/images/logo/logo-blue.png";
import Link from "next/link";

const Logo = () => {
  return (
    <div>
      <Link href="/">
        <Image
          priority
          src={Light_Logo}
          alt="Company logo"
          className="dark:grayscale dark:brightness-[4]"
        />
      </Link>
    </div>
  );
};

export default Logo;
