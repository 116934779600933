import { motion } from "framer-motion";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

interface CustomLinkProps {
  href: string;
  title: string;
  className?: string;
  toggle?: any;
}

export const CustomFooterLink = ({
  href,
  title,
  className = "",
}: CustomLinkProps) => {
  const router = useRouter();
  return (
    <div>
      <Link
        href={href}
        className={`${className} relative group hover:text-secondaryLight dark:hover:text-secondaryDark ${
          router.asPath === href
            ? "text-primaryLight dark:text-primaryDark"
            : "text-darkText dark:text-lightText"
        }`}
      >
        {title}
        <span
          className={`h-[1px] inline-block absolute left-0 -bottom-0.5 group-hover:w-full group-hover:bg-secondary text-secondaryLight dark:group-hover:bg-secondaryDark dark:text-secondaryDark transition-[width] ease duration-300 ${
            router.asPath === href
              ? "w-full bg-primaryLight dark:bg-primaryDark"
              : "w-0"
          }`}
        >
          &nbsp;
        </span>
      </Link>
    </div>
  );
};

export const CustomLink = ({
  href,
  title,
  className = "",
}: CustomLinkProps) => {
  const router = useRouter();

  return (
    <Link
      href={href}
      className={`${className} relative group hover:text-secondaryLight dark:hover:text-secondaryDark ${
        router.asPath === href
          ? "text-primaryLight dark:text-primaryDark"
          : "text-darkText dark:text-lightText"
      }`}
    >
      {title}
      <span
        className={`h-[1px] inline-block absolute left-0 -bottom-0.5 group-hover:w-full group-hover:bg-secondaryLight text-secondaryLight dark:group-hover:bg-secondaryDark text-secondaryDark transition-[width] ease duration-300 ${
          router.asPath === href
            ? "w-full bg-primaryLight dark:bg-primaryDark"
            : "w-0"
        }`}
      >
        &nbsp;
      </span>
    </Link>
  );
};

export const CustomNavLink = ({
  href,
  title,
  className = "",
  toggle,
}: CustomLinkProps) => {
  const router = useRouter();

  const handleClick = () => {
    toggle();
    router.push(href);
  };

  return (
    <motion.button
      className={`${className} overflow-hidden text-xl tablet:text-2xl relative group hover:text-secondaryLight dark:hover:text-secondaryDark ${
        router.asPath === href
          ? "text-primaryLight dark:text-primaryDark"
          : "text-darkText dark:text-lightText"
      }`}
      onClick={handleClick}
      initial={{ x: 50, opacity: 0 }}
      whileInView={{ x: 0, opacity: 1 }}
      transition={{ duration: 1, delay: 0.2, type: "spring" }}
      exit={{ x: 50, opacity: 0 }}
    >
      {title}
      <span
        className={`h-[1px] inline-block absolute left-0 -bottom-0.5 group-hover:w-full group-hover:bg-secondaryLight text-secondaryLight dark:group-hover:bg-secondaryDark text-secondaryDark transition-[width] ease duration-300 ${
          router.asPath === href
            ? "w-full bg-primaryLight dark:bg-primaryDark"
            : "w-0"
        }`}
      >
        &nbsp;
      </span>
    </motion.button>
  );
};
